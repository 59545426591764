<template>
  <div />
</template>

<script>
export default {
  created() {
    this.logout()
  },
  methods: {
    async logout() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      let errorOccured = false

      // set user inactive
      await this.$Users
        .updateResourceByUrl({
          url: userData['@id'],
          body: { onOff: false },
        })
        .catch(() => {
          errorOccured = true
        })

      // clear gps position
      await this.$Cars
        .updateResourceByUrl({
          url: this.$store.state.car,
          body: { latitude: '', longitude: '' },
        })
        .catch(() => {
          errorOccured = true
        })

      // clear state
      this.$store.dispatch('setCourierVisibility', false)
      this.$store.dispatch('setCourierLogState', false)
      this.$store.dispatch('setCourierCar', null)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(this.$JWT.config.storageTokenKeyName)
      localStorage.removeItem(this.$JWT.config.storageRefreshTokenKeyName)

      // Redirect to login page
      if(!errorOccured){
        this.$router.push({ name: 'login' })
      }
    },
  },
}
</script>